import React, { useState, useEffect } from "react";
import { useNavigate  } from 'react-router-dom'; 
import { useUser } from '../../User/userContext';
import { Box, Button, Grid, Typography, Card, CardContent, CardActions, Checkbox, Dialog, Stack, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress } from '@mui/material';
import { createRent, generatePaymentToken, getRentHistory } from "../actions";
import { formatDateDB, formatDateNoTime } from "../../util/formatters";
import { useStyles } from '../../../styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { lightMainColor, mainComponentColor, mainHeadingColor } from "../../../theme";

export default function Rent(props) {

    const classes = useStyles();
    const navigate = useNavigate();

    const { user, isAuthenticated, logout } = useUser();

    const [open, setOpen] = useState(false)
    const [amountDays, setAmountDays] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [rentHistory, setRentHistory] = useState([])
    const [disableAfter, setDisableAfter] = useState()
    const [messageToOwner, setMessageToOwner] = useState()

    const [focusedInput, setFocusedInput] = useState(null);

    const [rentId, setRentId] = useState()

    const [buttonLoading, setButtonLoading] = useState(false)

    let lat = null
    let long = null
    let addressName = null

    if(props.item.useDefaultStoreLocation && props.item.store){
        lat = props.item.store.latitude
        long = props.item.store.longitude
        addressName = props.item.store.addressName
    }
    else{
        lat = props.item.latitude
        long = props.item.longitude
        addressName = props.item.addressName
    }

    const latitude = props.item.useStoreLocation

    useEffect(() => {
        setOpen(props.open)
        getRentHistory(props.item.id, formatDateNoTime(new Date())).then(response => {
            setRentHistory(response)
        })
    }, [props.open])

    const calculateTotal = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
    
        // Calculate the time difference in milliseconds
        const timeDifference = end - start;
    
        // Calculate the difference in days, hours, and minutes
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        // Add 1 to include the start date as a selected day
        const selectedDays = daysDifference + 1;
        setAmountDays(selectedDays)
    }

    const handleDateRangeSelection = ({startDate, endDate}) => {
        if(startDate){
            setStartDate(startDate)
            handleStartDateChange(startDate)
        }
        endDate ? setEndDate(endDate): setEndDate(null)
        
        if(startDate && endDate) calculateTotal(startDate, endDate)
    };

    const handleStartDateChange = (newStartDate) => {
        if(!isAuthenticated){
            navigate('/base/login/')
        }
        const pickupDates = rentHistory.map(item => item.pickupDate)
        const newStartDateObj = new Date(newStartDate)
        const filteredPickupDates = pickupDates.filter(item => new Date(item) > newStartDateObj)
        const sortedDates = filteredPickupDates.sort((a, b) => new Date(a) - new Date(b));
        setDisableAfter(new Date(sortedDates[0]))
    };

    const isDateDisabled = (date) => {
        const dateObj = new Date(date)
        dateObj.setHours(0, 0, 0, 0);



        const minimumDays = props.item.minimum - 1;
        const currentStartDateObj = new Date(startDate)


        const minimumDate = new Date(currentStartDateObj);
        minimumDate.setDate(minimumDate.getDate() + minimumDays);


        if(dateObj < new Date()){
            return true
        }
        if(disableAfter && dateObj > disableAfter){
            return true
        }
        for (let i = 0; i < rentHistory.length; i++) {
            const item = rentHistory[i];
            const startDateObj = new Date(`${item.pickupDate}T12:00:00Z`)
            startDateObj.setHours(0, 0, 0, 0);

            const endDateObj = new Date(`${item.dropoffDate}T12:00:00Z`)
            endDateObj.setHours(0, 0, 0, 0);
            if(dateObj >= startDateObj && dateObj <= endDateObj){
                return true
            }
        }

        if(dateObj < minimumDate){
            return true
        }
        return false
    };

    const validateData = (value) => {
        return true
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonLoading(true)
        if(validateData()){
            let obj ={
                "dateEntered": formatDateDB(new Date()),
                "pickupDate": formatDateNoTime(startDate),
                "dropoffDate": formatDateNoTime(endDate),
                "messageToOwner": messageToOwner,
                "approved": null,
                "dateApproved": null,
                "userProfile_id": user.user.id,
                "item_id": props.item.id,
                "itemName" : props.item.name,
                "pricePerDay" : props.item.pricePerDay,
                "itemImage_id": props.images.id,
                "addressName": addressName,
                "latitude": lat,
                "longitude": long,
                "place_id": props.item.place_id
            }
            createRent(obj).then(response => {
                setRentId(response.id)
                generatePaymentToken(response.id).then(response2 => {
                    navigate(`/base/payment/${response2.token}`)
                })
            })
        }
        return false
    }

    const onConfirmDialog = () => {


    }

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={6} mt={1}>
                    <Stack direction="column" spacing={2} mt={2} fullWidth>
                    <Box sx={{ paddingBottom: 2 }}>
                                    <form onSubmit={handleSubmit}>
                                    <Grid item container justifyContent="center" spacing={2} xs={12} md={12} mt = {3}>
                                        <Grid item>
                                        <style>
                                            {`
                                                .CalendarDay__selected_start {
                                                    background: ${mainComponentColor};
                                                    border: 1px solid ${mainComponentColor};
                                                    color: white;
                                                }
                                                .CalendarDay__selected_end {
                                                    background: ${mainComponentColor};
                                                    border: 1px solid ${mainComponentColor};
                                                    color: white;
                                                }
                                                .CalendarDay__hovered_span {
                                                    background: ${lightMainColor};
                                                    border:${lightMainColor};
                                                    color: white;
                                                }
                                                .CalendarDay__selected_span {
                                                    background: ${lightMainColor};
                                                    border:${lightMainColor};
                                                    color: white;
                                                }
                                                .DayPicker__withBorder {
                                                    background-color: white;
                                                }
                                                .CalendarMonth {
                                                    background-color: white;
                                                }
                                            `}
                                        </style>
                                            <DateRangePicker 
                                                startDate={startDate}
                                                startDateId="start_date_id"
                                                endDate={endDate}
                                                endDateId="end_date_id"
                                                onDatesChange={handleDateRangeSelection}
                                                isDayBlocked={isDateDisabled}
                                                onFocusChange={(focused) => setFocusedInput(focused)}
                                                focusedInput={focusedInput}
                                                showClearDates
                                                orientation={window.innerWidth <= 768 ? "vertical" : "horizontal"}
                                            />
                                        </Grid>
                                        {amountDays ? 
                                            <Grid item>
                                                <Typography variant ="body1">
                                                    {amountDays} {amountDays === 1 ? "day" : "days"} at ${(props.item.pricePerDay).toFixed(2)} is a total of ${(amountDays * props.item.pricePerDay).toFixed(2)}
                                                </Typography>
                                            </Grid> 
                                        : 
                                            <Grid item>
                                                <Typography variant ="body1">You're almost there!</Typography>
                                            </Grid>
                                        }
                                        {/* <Grid item container xs = {12} sm = {12} md = {12} mt={2} justifyContent="center">
                                            <TextField multiline rows={4} fullWidth label="message owner (optional)" onChange={(e) =>{setMessageToOwner(e.target.value)}}/>
                                        </Grid> */}
                                        <Grid item container xs = {12} sm = {12} md = {12} justifyContent="center">
                                         {buttonLoading ? <CircularProgress /> : <Button disabled = {!startDate || !endDate} variant="contained" type ="submit">Checkout</Button>}
                                        </Grid>
                                    </Grid>
                                    </form>
                    </Box>
                    </Stack>
            </Grid>
        </Grid>
    )
}


