import React from "react";
import { useNavigate  } from 'react-router-dom'; 
import { useEffect } from "react";
import { Button, Grid, Typography, Card, CardContent, CardMedia, Divider, Chip } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { dateOptions, parseLocalDate } from "../features/util/formatters";
import { getRentalStatus } from "../features/util/generalHelpers";

export default function RentalCard({rh, index}) {

    const navigate = useNavigate();


    const handleClick = () =>{
        navigate(`/rentalinformation/${rh.id}`)
    }

    useEffect(() => {
        
    }, [rh])

    const labelColor = getRentalStatus(rh)


    console.log(rh.pickupDate)
    return (
        <>

        <Grid item xs={12} sm={12} md={12} ml={index === 0 ? 0 : 2} style={{borderRadius: '20'}}>   
            <Card>
                <CardContent>
                    <div style={{ cursor: 'pointer' }} onClick = {() => {handleClick()}}>
                        <Grid container style = {{ height: '400px'}}>
                            <Grid item xs={12} sm = {12} md = {12} style={{ height: '300px', overflow: 'hidden' }}>
                                <CardMedia
                                    component="img"
                                    image={`${process.env.REACT_APP_BACKEND_URL}/media${rh.image}`}
                                    title={rh.name}
                                    style={{ 
                                        height: '100%', 
                                        width: '100%', 
                                        objectFit: 'cover',  // Ensures the image covers the entire container
                                        objectPosition: 'center' // Centers the image
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8} sm = {8} md = {8}>
                                <Typography variant ="itemCardHeader" align="left" title={rh.name}>
                                    {rh.name.length > 90 ? `${rh.name.substring(0, 87)}...` : rh.name}
                                </Typography >
                            </Grid>
                            
                            <Grid item xs={12} sm ={12} md = {12} mt = {2} mb={1}>
                                <Divider/>
                            </Grid>
                            <Grid container>
                                <Grid item xs={7.5} sm = {7.5} md = {7.5}>
                                    <Typography>
                                        { parseLocalDate(rh.pickupDate).toLocaleDateString('en-US',dateOptions())}
                                    </Typography>
                                to 
                                    <Typography>
                                        {parseLocalDate(rh.dropoffDate).toLocaleDateString('en-US',dateOptions())}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4.5} sm = {4.5} md = {4.5} mt ={1} ml='auto'>
                                    <Typography>
                                        <Chip label={labelColor.label} sx={{ marginBottom: 1, height:'37px', borderRadius: '10px', '& .MuiChip-label': { fontSize: 10, color: "#fffcff", whiteSpace: 'normal'}, backgroundColor: labelColor.color }}/>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    </>)
}
