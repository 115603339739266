import React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, CircularProgress, useMediaQuery } from '@mui/material';
import { useUser } from '../../User/userContext';
import Resizer from 'react-image-file-resizer';
import { useStyles } from '../../../styles';
import ImageViewer from "../../../components/ImageViewer";
import WelcomeBanner from "../../../components/WelcomeBanner";
import ItemBanner from "../../../components/ItemBanner";
import CategoryStrip from "../../../components/CategoryStrip"
import { getItemsHomePage } from "../actions";
import ItemBannerVerticalEndless from "../../../components/ItemBannerVerticalEndless";

export default function Home() {

    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState()
    const classes = useStyles(); 

    const { user, location } = useUser();

    useEffect(() => {
        if(location){
            const origin = (location.coords.latitude).toString() + ',' + (location.coords.longitude).toString()
                getItemsHomePage(origin).then(response =>{
                    setItems(response)
                })
        }
        else{
            getItemsHomePage(false).then(response =>{
                setItems(response)
            })
        }
    }, [location])

    const banners = [
        'Why Buy? Just Rent!',
        'Borrow What You Need, Share What You Have',
        'A Community Only Needs 3 Hammers',
    ];

    const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'))

    return (
        <>
        <Grid container>
                <Grid container mt={3} alignItems="center" justifyContent="center">
                    <Grid item xs = {10}>
                        <WelcomeBanner banners={banners}/>
                    </Grid>
                </Grid>
                <Grid container mt = {3} alignItems="center" justifyContent="center">
                    <Grid item xs = {11}>
                        <ItemBannerVerticalEndless items={items} setXs={isXs ? 12 : 4}/>
                        {/* <ItemBanner key={1} items={items} scroll circular/> */}
                    </Grid>
                </Grid>
                {/* <Grid container mt = {3} alignItems="center" justifyContent="center">
                    <Grid item xs = {11}>
                        <CategoryStrip/>
                    </Grid>
                </Grid> */}
        </Grid>
    </>)
}

